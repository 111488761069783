.firebaseui-link {
    color: #f06292!important
}

.progressbar {
    background-color: #f06292!important;
}

.firebaseui-title, .firebaseui-input, .firebaseui-input-invalid  {
    color: #505050!important;
    font-family: Candara, Futura, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif!important
}
.mdl-button--primary.mdl-button--primary {
    color: #f06292!important
}

.mdl-button--raised.mdl-button--colored {
    background: #f06292!important;
    color: #ffffff!important
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
    background-color: #f06292!important
}